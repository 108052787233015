/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import {Sidebar} from "primereact/sidebar";
import {Ruta} from "./Rutas/ruta";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import FormaRuta from "./Rutas/formaRuta";
import {useNavigate, useParams} from "react-router-dom";
import alerts from "../../../shared/alerts";
import produce from "immer";
import constantes from "../../constantes";
import CrearConveio from "./crearConvenio";
import FieldAutoComplete from '../../../shared/controls/fieldAutoComplete';
import * as numeral from "numeral";
import DetalleRutaGrid from "./Rutas/detalleRuta";
import moment from "moment";
import useApiCotizaciones from "../../../api/comercial/cotizaciones";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import {useLoadingDispatch} from "../../../layout/show-loading-context";
import useSeguridad from '../../../Seguridad/useSeguridad';
import {default as constantesCatalogos} from '../../../catalogos/constantes';
import {useEmpresa} from "../../../layout/EmpresaProvider";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

const DetalleCotizacion = () => {
    const seguridad = useSeguridad();
    const puedeEditar = seguridad.esEditable(constantesCatalogos.menus.comercial);
    const puedeModificarCosto = seguridad.esEditable(constantesCatalogos.menus.modificarCostoRutas);
    const puedeModificarComentarios = seguridad.esEditable(constantesCatalogos.menus.modificarComentariosCotizacion);
    const dispatchRoot = useLoadingDispatch();
    const [cotizacion, setCotizacion] = useState({
        cliente: '',
        unidadDeMedida: constantes.kilometros,
        fecha: moment().startOf('day').toDate(),
        rutas: [],
        id: 0
    });
    const [sidebar, setSidebar] = useState({visible: false});
    const [gridApi, setGridApi] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [editable, setEditable] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [enabledClone, setEnabledClone] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [tieneRutas, setTieneRutas] = useState(0);
    const history = useNavigate();
    const apiCotizaciones = useApiCotizaciones();
    let {id, clonar} = useParams();

    const {disable: disableEmpresa, getUnidadMedidaDefault, empresa} = useEmpresa();

    useEffect(() => {
        disableEmpresa();
    }, []);


    async function back() {
        if (isDirty === false || await alerts.preguntarSiDeseaSalirSinGuardar()) {
            history('/comercial');
        }
    }

    async function submit() {
        setSubmitted(true);
        if (!clienteNoEncontrado() && cotizacion.fecha != null) {
            const cotizacion = await saveCotizacion();
            await loadCotizacion(gridApi, false, cotizacion.id);
            console.log(cotizacion)
        }
    }

    async function saveCotizacion() {
        let cotizacionGuardada = await apiCotizaciones.guardar(
            {...cotizacion, rutas: agGridHelper.getData(gridApi).filter(e => e.esDetalle !== true)}
        );
        setIsDirty(false);
        return cotizacionGuardada;
    }

    function actualizar(value, propertyName) {
        const nuevaCotizacion = propertyName != null ? produce(cotizacion, draft => {
            draft[propertyName] = value
        }) : value;
        setCotizacion(nuevaCotizacion);
        setIsDirty(true);
    }


    const onGridReady = async (params) => {
        setGridApi(params.api);
        if (id > 0) {
            await loadCotizacion(params.api, clonar);
        } else {
            const cotizacionDefault = {...cotizacion, unidadDeMedida: getUnidadMedidaDefault()};
            setCotizacion(cotizacionDefault);
            params.api.setRowData([]);
        }
    };


    const editRuta = (ruta, isNew) => {
        setSidebar({visible: true, item: ruta, isNew: isNew});
    };

    function saveRuta(ruta, agregarOtra) {
        const newRuta = new Ruta(ruta);
        agGridHelper.updateData(gridApi, newRuta);
        setIsDirty(true);
        if (agregarOtra !== true) {
            hide();
        }
        gridApi.resetRowHeights();
        setTieneRutas(true);

    }

    function hide() {
        setSidebar({visible: false});
    }

    function deleteRuta(api, index) {
        setIsDirty(true);
        agGridHelper.deleteData(api, index);
        setTieneRutas(agGridHelper.getData(api).length > 0);
    }

    function onSelectionChanged(event) {
        setSelectedRows(event.api.getSelectedNodes());
    }


    async function clonarRuta(api) {

        setEnabledClone(false);

        let row = api.getSelectedRows().pop();
        row.accesorios.map((item) => (
            item.id = 0
        ));

        if (row == null) {
            alerts.seleccionarRuta();
            return;
        }

        if (api.getSelectedRows().length === 1)
            setEnabledClone(true);
        else
            return;


        let rutaClon = produce(row, draft => {
            draft.id = 0;
            draft.key = Math.random();
            draft.costo.gastos.map(e => new Ruta(e.id = 0));
        });


        editRuta(rutaClon, false);

    }

    async function loadCotizacion(gridApi, clonar, newId) {
        let cotizacion = clonar === 'true' ?
            await apiCotizaciones.clonar(newId ?? id) :
            await apiCotizaciones.obtener(newId ?? id);
        setCotizacion(cotizacion);
        dispatchRoot({type: 'setDatosPath', datosPath: cotizacion?.codigoCotizacion});

        setEditable(cotizacion.estatus == null || cotizacion.estatus === constantes.estatusCotizaciones.enProceso);
        setIsDirty(clonar === 'true');
        gridApi.setRowData(cotizacion.rutas.map(e => new Ruta(e)));
        setTieneRutas(cotizacion.rutas.length > 0);
    }


    const ButtonsHeader = ({context, api}) => {
        return (
            <>
                {puedeEditar &&
                    <div className={'ag-grid header-icons'}>
                        <ButtonIcon title="Clonar" disabled={!enabledClone} onClick={() => clonarRuta(api)}
                                    iconName={'IoDuplicateOutline'}/>
                        <ButtonIcon title="Agregar Ruta" className="grid-action" iconName={'MdAddBox'}
                                    onClick={() => context.editRuta(new Ruta(), true)}/>
                    </div>
                }
            </>
        );
    };

    const ExpandCollapseButtons = ({data, rowIndex, api}) => (
        <div className={'ag-grid row-icons'}>
            {data.expandido !== true && data.esDetalle !== true &&
                <ButtonIcon title={'Expander'} iconName={'MdExpandMore'}
                            onClick={() => expander(data, rowIndex, api)}/>
            }
            {data.expandido === true && <ButtonIcon title={'Contraer'} iconName={'MdExpandLess'}
                                                    onClick={() => contraer(data, rowIndex, api)}/>
            }
        </div>
    );

    const FacturacionRenderer = ({data}) => <div>
        <div>
            {numeral(data.facturacionMxn).format('$0,0.00')}
        </div>
        {data.facturacionUsd === true && empresa.moneda.id !== constantes.monedaUsd.id &&
            <div> {numeral(data.montoFacturacionUsd).format('0,0.00')} USD</div>
        }
    </div>;

    function expander(data, rowIndex, api) {
        agGridHelper.insertDataAt(api, {...data, esDetalle: true, key: Math.random().toString()}, rowIndex + 1);
        data.expandido = true;
        agGridHelper.updateData(api, data)
    }

    function contraer(data, rowIndex, api) {
        agGridHelper.deleteData(api, rowIndex + 1);
        data.expandido = false;
        agGridHelper.updateData(api, data)
    }

    function isFullWidth(data) {
        return data.esDetalle === true;
    }

    function descargarPdf() {
        // const pdfUrl = `${uri}operaciones/comercial/Cotizaciones/${cotizacion.id}/pdf/NA/0/cotizacion_${cotizacion.codigoCotizacion}.pdf`;
        // window.location.replace(pdfUrl);
        apiCotizaciones.descargar(cotizacion.id, cotizacion.codigoCotizacion);
    }

    async function cancelarCotizacion() {
        if (await alerts.preguntarSiNoAdvertencia('¿Desea cancelar la cotización?') === true) {
            await apiCotizaciones.cancelar(id);
            history('/comercial');
        }
    }

    function clienteNoEncontrado() {
        return (cotizacion.cliente ?? '') === '';
    }

    const RowButtons = ({data, rowIndex, api, context, colDef, ...props}) => {
        return (
            <div className={'ag-grid row-icons'}>
                <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editRuta(data, false)}/>
                {colDef.editable &&
                    <ButtonIcon title={'Eliminar'} iconName={'RiDeleteBinLine'}
                                onClick={() => context.deleteRuta(api, rowIndex)}/>
                }
            </div>
        )
    };

    const renderFooterModal = () => {
        return (
            <div>
                {editable ?
                    <Button label="Aceptar" onClick={() => setVerModal(false)} className="p-button-outlined"/>
                    :
                    <Button label="Guardar" onClick={guardarComentarios} className="p-button-outlined"/>
                }
            </div>
        );
    };

    const guardarComentarios = async () => {
        await apiCotizaciones.comentarios(id, {comentario: cotizacion.comentario});
        setVerModal(false);
    };

    return (
        <>
            <div className="form-filtro form-row">
                <div className={`form-group col-md-4 col-sm-6`}>
                    <label htmlFor={'cliente'}>Cliente</label>
                    {clienteNoEncontrado() &&
                        <label className="autocomplete-not-found-item" style={{right: 150}}>No encontrado</label>}
                    <div className="col-auto">
                        <FieldAutoComplete name="cliente" disabled={!editable} source="clientes" freeText={true}
                                           colMd={3} params={{soloActivos: true}}
                                           errors={cotizacion.cliente?.id === 0 && submitted} value={cotizacion.cliente}
                                           onChange={actualizar}/>
                    </div>
                </div>
                <FieldCalendar name="fecha" label="Fecha" colMd={2} error={(cotizacion.fecha ?? '') === '' && submitted}
                               disabled={!editable}
                               value={cotizacion.fecha} onChange={actualizar}/>
                <div className={`form-group col-md-2 col-sm-6}`}>
                    <label>&nbsp;</label>
                    <FieldRadioButtonList name="unidadDeMedida" source="unidadesDeMedidaLongitud" valueIsObject={true}
                                          disabled={!editable || tieneRutas === true}
                                          value={cotizacion.unidadDeMedida} onChange={actualizar}/>
                </div>
                <div className="field-iconos btn-group">
                    <ButtonIcon title='Regresar' iconName={'BiArrowBack'} onClick={back}/>
                    {/*<ButtonIcon title='Descargar' iconName={'BiDownload'}/>*/}
                    {editable && <>
                        <ButtonIcon title='Cancelar' iconName={'FcCancel'} onClick={cancelarCotizacion}/>
                    </>
                    }
                    {(editable || puedeModificarCosto || puedeModificarComentarios) &&
                        <ButtonIcon title='Guardar' iconName={'AiOutlineSave'} onClick={submit}/>
                    }
                    <ButtonIcon title='Agregar Comentario'
                                iconName={cotizacion?.comentario ? 'BiMessageRoundedDetail' : 'BiMessageRounded'}
                                onClick={() => setVerModal(true)}/>
                    <ButtonIcon title='Enviar Correo' className={'secondary-icon'} iconName={'AiOutlineMail'}/>
                    {editable && <CrearConveio cotizacion={cotizacion} saveCotizacion={saveCotizacion}
                                               selectedRows={selectedRows} isDirty={isDirty}/>}
                    {(cotizacion?.estatus === constantes.estatusCotizaciones.enviada || cotizacion?.estatus === constantes.estatusCotizaciones.enConvenio) &&
                        <ButtonIcon title="Descargar Cotización"
                                    className={'secondary-icon'}
                                    iconName={'AiOutlineFileDone'}
                                    onClick={descargarPdf}/>
                    }
                </div>
            </div>

            <Sidebar visible={sidebar.visible} position="right" dismissable={false}
                     className="p-sidebar-md" onHide={hide}>
                <FormaRuta control={sidebar} onSave={saveRuta} unidadDeMedida={cotizacion.unidadDeMedida}
                           editable={editable} puedeModificarCosto={puedeModificarCosto} modificarComentarios={puedeModificarComentarios} tipoUnidad={cotizacion.tipoUnidad}/>
            </Sidebar>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    onSelectionChanged={onSelectionChanged}
                    enableSorting={true}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowSelection={'multiple'}
                    immutableData={true}
                    onGridReady={onGridReady}
                    frameworkComponents={{
                        rowButtons: RowButtons,
                        buttonsHeader: ButtonsHeader,
                        expandCollapseButtons: ExpandCollapseButtons,
                        detalleRutaGrid: DetalleRutaGrid,
                        facturacionRenderer: FacturacionRenderer
                    }}
                    rowClassRules={{
                        'com-ruta-aceptada': ({data}) => data.estatus?.id === constantes.estastusRutas.aceptada,
                        'com-ruta-rechazada': ({data}) => data.estatus?.id === constantes.estastusRutas.rechazada,
                    }}
                    isFullWidthCell={function (rowNode) {
                        return isFullWidth(rowNode.data);
                    }}
                    getRowHeight={function ({data}) {
                        if (isFullWidth(data)) {
                            return 90;
                        }
                        if (data.facturacionUsd === true)
                            return 50;
                    }}
                    fullWidthCellRenderer={'detalleRutaGrid'}
                    getRowNodeId={(data) => data.key}
                    context={{
                        editRuta: editRuta,
                        deleteRuta: deleteRuta
                    }}
                >
                    <AgGridColumn field="expandir" headerName="" maxWidth={70} minWidth={70}
                                  cellRenderer="expandCollapseButtons"
                                  cellClass={'icons'}/>
                    <AgGridColumn field="viaje.origen.nombre" headerName="ORIGEN"/>
                    <AgGridColumn field="viaje.destino.nombre" headerName="DESTINO"/>
                    <AgGridColumn field="viaje.tipoViajeNombre" headerName="TIPO DE VIAJE" minWidth={130} flex={3}/>
                    <AgGridColumn field="viaje.tipoUnidad.nombre" headerName="TIPO DE UNIDAD" minWidth={130} flex={3}/>
                    <AgGridColumn field="costo.longitud"
                                  headerName={cotizacion.unidadDeMedida.abreviatura.toUpperCase()} maxWidth={80}
                                  cellClass={'right'}
                                  valueFormatter={agGridHelper.decimalFormatter}/>
                    <AgGridColumn field="costo.costoRuta" headerName="COSTO RUTA" maxWidth={130} cellClass={'right'}
                                  valueFormatter={agGridHelper.currencyFormatter}/>
                    <AgGridColumn field="facturacionMxn" headerName="FACTURACIÓN" maxWidth={130} cellClass={'right'}
                                  cellRenderer="facturacionRenderer"/>
                    <AgGridColumn field="precioPorKilometro"
                                  headerName={`PRECIO POR ${cotizacion.unidadDeMedida.abreviatura.toUpperCase()}`}
                                  maxWidth={130}
                                  cellClass={'right'} valueFormatter={agGridHelper.currencyFormatter}/>
                    <AgGridColumn field="utilidad" headerName="UTILIDAD" maxWidth={130} cellClass={'right'}
                                  valueFormatter={agGridHelper.currencyFormatter}/>
                    <AgGridColumn field="margen" headerName="MARGEN" maxWidth={130} cellClass={'right'}/>
                    <AgGridColumn field="estatus.nombre" headerName="" maxWidth={100} hide={editable}/>
                    <AgGridColumn field="seleccion" headerName="" maxWidth={40} headerCheckboxSelection={true}
                                  checkboxSelection={true} hide={!editable}/>
                    <AgGridColumn field="acciones" maxWidth={70} minWidth={70} cellRenderer="rowButtons"
                                  headerComponent="buttonsHeader"
                                  editable={editable}
                                  cellClass={'icons'} pinned={'right'}/>
                </AgGridReact>
            </div>
            <Dialog visible={verModal} onHide={() => setVerModal(false)} style={{width: '35vw'}}
                    footer={renderFooterModal} header={"Comentarios"}>
                <div className={"form-row"}>
                    <FieldTextArea name="comentario" label="Comentario" value={cotizacion.comentario} colMd={12}
                                   rows={3} disabled={false}
                                   onChange={actualizar}/>
                </div>
            </Dialog>
        </>
    )
};

export default DetalleCotizacion;
