/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import AddButton from "../../../shared/Grid/Buttons/AddButton";
import DeleteButton from "../../../shared/Grid/Buttons/DeleteButton";
import alerts from "../../../shared/alerts";
import useApiCapacitacion from "../../../api/administracion/useApiCapacitacion";
const GeneralCapacitacion = ({value, onChange, onValidate, puedeEditar}) => {   
    const api = useApiCapacitacion();
    console.log(value)
    const quitarCapacitacion = async (empleadoid, eliminar) => {
        console.log('delete')
        if ((await alerts.preguntarSiNo("¿Desea eliminar el empleado de la capacitación?")) === true) {
            eliminar();
            await api.quitarEmpleadoCapacitacion(value?.id, empleadoid);
        }
    };


    return (
        <>
              
               <Form columns={4} values={value} onChange={onChange} onValidate={onValidate}
                  formDisabled={!puedeEditar}>

                <Field label={'Fecha Capacitación'} field={'fechaCapacitacion'} render={FormCalendar} required={true}
                       cols={2}/>
              <Field label={"Departamento Capacitacion"} field={"departamentoCapacitacionId"} source={"departamentosIncidencia"}
                       render={FormDropDown}
                       required={true} cols={2}/>
                <Field label={"Tema Capacitación"} field={"tema"} render={FormTextBox} 
                       required={true} cols={2}/>
                <Field label={'Duración (Hrs)'} field={'duracion'} maxFractionDigits={2} cols={2} 
                       render={({...props}) => <FormInputNumber {...props} required={true}/>}/>
                <Field field={'capacitadorId'} label={'Capacitador'} cols={2}
                       render={({onParentChange, parent, ...props}) => <FormDropDown {...props} required={true}
                                                                                     source="empleados"
                                                                                     params={{                                                                                         
                                                                                         todasLasEmpresas: true
                                                                                     }}
                                                                                   
                                                                                    />
                       }
                />
                <Field label={"Tipo"} field={"tipoCapacitacionId"} source={"tipoCapacitacion"} render={FormDropDown}
                       required={true} cols={2}/>
                <Field label={"Formato Capacitación"} field={"formatocapacitacionId"} source="capacitacionFormato"
                       render={FormDropDown}
                       required={true} cols={2}/>          
                <Field label={'Evidencia'} field={'evidencia'} render={FormClipboardImage} required={false} cols={2}/>     
              
                <Field field={"empleadosCapaacitaciones"} cols={4} render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value}
                        onChange={(data) => onParentChange({ empleadosCapaacitaciones: data })}
                        getEntityId={(row) => row._uniqueUid}
                    >
                        <ExColumn field="empleadoid" title="Empleado" source="empleados" required={true}  width={'23%'}
                          cellRenderer={({ row, onRowChanged,...props }) => (
                                <FormDropDown {...props}                                        
                                              source="empleados"                                                 
                                                 params={{                                                                                         
                                                 todasLasEmpresas: true                                                                                        
                                                 }}
                                                 
                                                 onChange={(empleadoid,item) => onRowChanged({
                                                        empleadoid,
                                                        empresaId: item?.empresaId,
                                                        unidadDeNegocioId: item?.unidadNegocioId,
                                                        departamentoId: item?.departamentoId,
                                                        puestoId: item?.puestoId,
                                                        tipoEmpleadoAOId: item?.tipoEmpleadoAOId, 
                                                        
                                                        })}
                                               
                                              disabled={false} style={{ width: '100%' }}
                                />
                            )} />
                        <ExColumn field="empleadoid" title="N°Empleado" required={true} disabled={true} width={'6%'}
                            cellRenderer={ ({row,...props})=> <FormTextBox {...props}/>} />
                        <ExColumn field="empresaId" title="Empresa" required={true} width={'8%'}
                          cellRenderer={({ row, ...props }) => (
                                <FormDropDown {...props}
                                            source="empresas"
                                            disabled={true} style={{ width: '100%' }}
                                           
                                />
                            )} />

                            <ExColumn field="unidadDeNegocioId" title="Unidad de Negocio" width={'12%'}
                            cellRenderer={({ row, ...props }) => (
                                <FormDropDown {...props} 
                                            source="unidadesNegocio"
                                            disabled={true} style={{ width: '100%' }}
                                          
                                />
                            )} />

                            <ExColumn field="departamentoId" title="Departamento" required={true} width={'15%'}
                            cellRenderer={({ row, ...props }) => (
                                <FormDropDown {...props} 
                                            source="departamentos"
                                            disabled={true} style={{ width: '100%' }}
                                         
                                />
                            )} />

                        <ExColumn field="puestoId" title="Puesto" required={true} width={'15%'}
                            cellRenderer={({ row, ...props }) => (
                                <FormDropDown {...props} 
                                            source="puestos"
                                            disabled={true} style={{ width: '100%' }}
                                           
                                />
                            )} />

                        <ExColumn field="tipoEmpleadoAOId" title="Tipo Empleado" required={true} width={'10%'}
                            cellRenderer={({ row, ...props }) => (
                                <FormDropDown {...props} 
                                            source="tipoEmpleadoAdmOp"
                                            disabled={true} style={{ width: '100%' }}
                                           
                                />
                            )} />
                     
                       <ExColumn field="calificacion" title="Calificación" mode="decimal" minFractionDigits={2} maxFractionDigits={2} required={true} width={'6%'}
                            cellRenderer={ ({row,...props})=> <FormInputNumber disabled={false} style={{ width: '100%' }} {...props}/>} />

                 
                        <ExColumn field={"command"}
                                  headerRenderer={({onAdd})=> <AddButton onAdd={onAdd}/>}
                                  cellRenderer={({row, onDelete})=> <DeleteButton onDelete={() => quitarCapacitacion(row.empleadoid, onDelete)} /> } 
                                  width={'50px'} />
                 
                    </ExGrid>
                } />
            </Form>
            
        </>
    );
};

export default GeneralCapacitacion;
