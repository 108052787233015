/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import numeral from "numeral";
import moment from "moment";

const Desgloce = ({ value }) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>                                         
                        <th style={{width: '20%'}}>CONCEPTO</th>
                        <th style={{width: '40%'}}>DETALLE</th>
                        <th style={{width: '10%'}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {value.desgloce?.map((item, index) => {
                        return (
                            <tr key={index}>                                                     
                                <td>{item.concepto}</td>
                                <td className="p-d-flex p-flex-column">
                                <span>Fecha de Solicitud: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                <span>Solicitante: {item.solicitante}</span>                                
                                    <div>
                                        # Exhibiciones: <span className="bold">{item.numeroDeParcialidades}</span> |
                                        # Pago: <span className="bold">{item.parcialidad}/{item.numeroDeParcialidades}</span>
                                    </div>                                   
                                </td>
                                <td>{numeral(item.monto).format('$0,0.00')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default Desgloce;
