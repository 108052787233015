import React from "react";
import {useState} from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import useApiEmpleados from "../../api/catalogos/useApiEmpleados";
import FiltroEmpleados from "./filtroEmpleados";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import {Sidebar} from "primereact/sidebar";
import DetalleEmpleado from "./detalleEmpleado";
import constantes from "../constantes";
import {getSessionState} from "../../hooks/useSessionState";
import Filtro from "../../shared/componentes/filtro";
import useSeguridad from "../../Seguridad/useSeguridad";

const Empleados = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.administracionPersonal);
    const filtroKey = 'filtrosEmpleados';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [empleadoId, setEmpleadoId] = useState(false);
    const apiEmpleados = useApiEmpleados();
    const defaultFilter = {
        nombre: "",
        estatus: [
            {id: constantes.estatus.empleados.enProceso},
            {id: constantes.estatus.empleados.alta},
        ]
    };

    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);
        let nombre = filtro?.nombre ?? "";
        nombre = nombre.trim();
        nombre = nombre.replaceAll(/\s+/g, " ");
        return {
            ...filtro,
            departamentoId: filtro.departamento?.id,
            puestoId: filtro.puesto?.id,
            estatus: filtro.estatus?.map(e => e.id),
            nombre: nombre
        };
    };

    async function buscar(filtro, paramsApi) {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await apiEmpleados.filtrar(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setEmpleadoId(0);
    }

    function onUpdateStatus() {
        setSidebarVisible(false);
        buscar();
    }

    function exportar() {
        apiEmpleados.excel(obtenerFiltro(getSessionState(filtroKey)));
    }

    function exportarFam() {
        apiEmpleados.excelFamiliares(obtenerFiltro(getSessionState(filtroKey)));
    }

    function exportarDopings() {
        apiEmpleados.excelDopings(obtenerFiltro(getSessionState(filtroKey)));
    }
    function exportarInfoAdicional(){
        apiEmpleados.excelInformAdicional(obtenerFiltro(getSessionState(filtroKey)));
    }

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                <ButtonIcon title={'Descargar Familiares'} onClick={exportarFam} iconName={'BiDownload'} />
                <ButtonIcon title={'Descargar Antidoping'} onClick={exportarDopings} iconName={'BiDownload'} />
                <ButtonIcon title={'Descargar Información Adicional'} onClick={exportarInfoAdicional} iconName={'BiDownload'} />
                {!soloLectura && (
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                )}
            </div>
        );
    };
    

    const editEmpleado = (id) => {
        setSidebarVisible(true);
        setEmpleadoId(id);
    };

    const RowButtons = ({data, rowIndex, api, context}) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editEmpleado(data.id)}/>
        </div>
    );
    const EstatusOperadorViajeCellRenderer = (value) => {
        if (
            value !== undefined &&
            (value.data.estatusViaje)
        ) {
            return <img src="../../../../img/warning.png" alt="Imagen" width="15px" style={{marginLeft: "2px"}}/>;
        } else {
            return null;
        }
    };

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                     className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
                <DetalleEmpleado empleadoId={empleadoId} onSave={buscar} onUpdateStatus={onUpdateStatus}
                                 soloLectura={soloLectura}/>
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}
                    classButtons="field-iconos btn-group">
                <FiltroEmpleados/>
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter,
                            estatusOperadorViajeCellRenderer: EstatusOperadorViajeCellRenderer,
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editEmpleado: editEmpleado
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn maxWidth={40}
                                  cellClass={'imagen'}
                                  cellRenderer="imageFormatter"/>
                    <AgGridColumn field="nombre" headerName="NOMBRE" flex={5} minWidth={280} cellClass={'left'}
                                  headerClass={'left'}/>
                    <AgGridColumn field="codigoEmpleado" headerName="ID" flex={2}/>
                    <AgGridColumn field="departamento" headerName="DEPARTAMENTO" cellClass={'left'} headerClass={'left'}
                                  flex={5}/>
                    <AgGridColumn field="puesto" headerName="PUESTO" cellClass={'left'} headerClass={'left'}/>
                    <AgGridColumn field="jefeInmediato" headerName="JEFE INMEDIATO" cellClass={'left'}
                                  headerClass={'left'} flex={5}/>
                    <AgGridColumn field="fechaIngreso" headerName="FECHA INGRESO" cellRenderer="dateFormatter"
                                  flex={3}/>
                    <AgGridColumn field="antiguedad" headerName="ANTIGÜEDAD"/>
                    <AgGridColumn field="documentosPendientes" headerName="DOCS. PENDIENTES" flex={3}/>
                    <AgGridColumn field="fechaFinViaje" headerName="FECHA FIN VIAJE" cellRenderer="dateFormatter"
                                  flex={3}/>
                    <AgGridColumn maxWidth={200} field="ESTATUS OPERADOR"
                                  cellClass={'imagen'}
                                  cellRenderer="estatusOperadorViajeCellRenderer"/>
                    <AgGridColumn field="estatus" headerName="ESTATUS" flex={3}/>
                    <AgGridColumn
                                  headerName="VER"
                                  cellRenderer="rowButtons"
                                  pinned={'right'}
                                  cellClass={'left'}
                                  headerComponent="buttonsHeader"/>
                </AgGridReact>
            </div>
        </>
    )
};

export default Empleados;
