/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Sidebar} from "primereact/sidebar";
import useForm from "../../shared/forms/useForm";
import Field from "../../shared/forms/Field";
import Form from "../../shared/forms/Form";
import moment from "moment";
import FormTabView from "../../shared/forms/Tabs/FormTabView";
import General from "./Edicion/generalCapacitacion"
import {Button} from "primereact/button";
import {serialize} from "object-to-formdata";
import useApiCapacitacion from "../../api/administracion/useApiCapacitacion";


const CapacitacionDetalle = ({capacitacionId, onCancel, onSaved, puedeEditar, onRefresh, empleado}) => {
    const initialValues = {
        capacitacion: {
            id: 0,
            fechaCapacitacion: moment().startOf('day')._d
        }
    };
    const visible = capacitacionId !== null;
    const [submitted, setSubmitted] = useState(false);
    const {
        values: item,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);
    const api = useApiCapacitacion();
       const onLoad = async () => {
        if (capacitacionId === 0 || capacitacionId === null) {
            setValues(initialValues);
            return;
        }

        let result = await api.obtener(capacitacionId);
        console.log(result)
        result.saldoAnterior = result.saldo;
        const item = {
            capacitacion: result
        };
        setValues(item);
       
    };

    useEffect(() => {
        onLoad().then();
    }, [capacitacionId]);

    const onSave = async () => {
        setSubmitted(true);

        if (!isValid)
            return;

        const formData = serialize(item?.capacitacion ?? {}, {indices: true, dotsForObjectNotation: true});
        await api.guardar(formData);
        onSaved();
    }


  
    return (
        <>
            <Sidebar visible={visible} position="right" dismissable={false}
                     className="p-sidebar-bg" onHide={onCancel} style={{ width: '1350px' }}>
                <Form columns={1} values={item} onChange={onChange}
                      onValidate={onValidate} submitted={submitted} formDisabled={item?.capacitacion?.id > 0}
                      className={`form-container full-height ${submitted ? 'submitted' : ''}`}>
                    <FormTabView>
                        <Field title="General" field={'capacitacion'}
                               render={(props) => <General {...props} puedeEditar={puedeEditar}/>}/>
                    
                       
                   
                    </FormTabView>
                </Form>
                <div className="form-footer">
                    <Button label="Salir" onClick={onCancel} className="p-button-outlined"/>
                    <Button label="Guardar" type="button"
                        onClick={onSave}/>        
                </div>
            </Sidebar>
        
        </>
    );
};

export default CapacitacionDetalle;
