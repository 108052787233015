/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
const CapacitacionesEmpleados = ({capacitaciones, onChange, soloConsulta}) => {
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                    <tr>                   
                        <th style={{width: '35%'}}>TEMA</th>
                        <th style={{width: '25%'}}>FECHA</th>
                        <th style={{width: '15%'}}>TIPO</th>     
                        <th style={{width: '15%'}}>FORMATO CAPACITACION</th>         
                        <th style={{width: '35%'}}>CALIFICACION</th> 
                    </tr>
                    </thead>
                    <tbody>
                    {capacitaciones?.map((item, index) => {
                        // Asegúrate de que item.capacitacion existe antes de acceder a sus propiedades
                        const capacitacion = item.capacitacion;

                        return (
                            <tr key={index}>                                                            
                                <td>{capacitacion?.tema}</td>
                                <td>{moment(capacitacion?.fechaCapacitacion).format('DD/MM/yyyy')}</td>
                                <td>{capacitacion?.tipoId?.nombre || 'Tipo no disponible'}</td> 
                                <td>{capacitacion?.formatocapacitacionId?.nombre}</td> 
                                <td>{item?.calificacion}</td>
                            </tr>
                        );
                    })}
                </tbody>
                </table>
            </div>
        </>
    )
};


export default CapacitacionesEmpleados;
