import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer } from 'react';
import useApiViaje from '../../../api/operaciones/useApiViajes';
import FieldDropDown from '../../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import formReducer from '../../../shared/forms/formReducer';
import FormHandler from "../../../shared/forms/formHandler";
import { agregarDescuento } from "./viajeValidacionEsquema";

const AgregarDescuento = ({ viajeId, onSave, visible, hide }) => {
    const api = useApiViaje();
    const defaultEntity = () => ({
        entity: {
            operador: null,
            descuento: null,
            exhibiciones: null,
            monto: null,
            comentario: null
        }
    });

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);

    const formHandler = new FormHandler({
        validationSchema: agregarDescuento,
        api,
        dispatch
    });

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        if (seguir.result) {
            const nuevo = await api.agregarDescuento(viajeId, entity);
            onSave(nuevo);

            dispatch({
                type: "update",
                value: { ...defaultEntity }
            });
        }
    };

    const close = () => {
        dispatch({
            type: "update",
            value: { ...defaultEntity }
        });
        hide();
    }

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                <Button label="Guardar" onClick={guardar} autoFocus />
            </div>
        );
    }

    return (
        <Dialog header="Agregar descuento" visible={visible} style={{ width: '60vw' }} footer={renderFooterModal()} onHide={close}>
            <div className="form-row">
                <FieldDropDown name="operador" source="operadoresSegmento" value={entity.operador} label="Operador"
                    params={{ viajeId: viajeId }} required colMd={12} error={errors?.operador}
                    onChange={actualizar} />
                <FieldDropDown name="descuento" source="descuentos" value={entity?.descuento} label="Descuento"
                    required colMd={6} error={errors?.descuento} onChange={actualizar} />
                <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                    required colMd={6} error={errors?.monto}
                    onChange={actualizar} />
                <FieldInputNumber name="exhibiciones" value={entity.exhibiciones} fractionDigits={2} label="No. Exhibiciones"
                    required colMd={6} error={errors?.exhibiciones}
                    onChange={actualizar} />             
                <FieldCalendar name="fechaInicio" label="Fecha de inicio de descuento" colSm={6} required
                            value={entity.fechaInicio} className="xs" showButtonBar={false} error={errors?.fechaInicio} onChange={actualizar} />       
                <FieldTextArea name="comentario"  label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                         error={errors?.comentario} onChange={actualizar} />
            </div>
        </Dialog>
    )
}

export default AgregarDescuento;