/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import { AgCurrencyFormatter, AgDateFormatter } from "../../../shared/ag-grid-helper/GridFormatters";
import useForm from "../../../shared/forms/useForm";
import moment from "moment";
import useApiDieselTanque from "../../../api/taller/useApiDieselTanque";
import CargaDetalle from "./CargaDetalle";
import Filters from "../../../shared/forms/FormFilter/Filters";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormCalendarRange from "../../../shared/forms/FormFields/FormCalendarRange";
import Buttons from "../../../shared/forms/FormFilter/Buttons";
import FormFilter from "../../../shared/forms/FormFilter/FormFilter";
import TanqueVista from "./TanqueVista";
import { v4 as uuidv4 } from 'uuid';

const filtroInicial = {
    fecha: [moment().startOf('month')._d, moment().startOf('day')._d]
};

const Tanques = ({ combustibleId, soloLectura }) => {
    const api = useApiDieselTanque();
    const [datos, setDatos] = useState([]);
    const [tanques, setTanques] = useState([]);
    const [cargaId, setCargaId] = useState(null);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);
    const [exportGuid, setExportGuid] = useState(null);
   const [,setSidebarVisible] = useState(false);

    useEffect(() => {
        if (exportGuid != null)
            api.excel({ ...filter, combustibleId }).then();
    }, [exportGuid]);

    const filtrar = async (filtro) => {

        setDatos([]);
        const [tanques, datos] = await Promise.all([
            api.filtrar({ combustibleId }),
            api.filtrarCargas({ ...filtro, combustibleId })]
        );

        setTanques(tanques);
        setDatos(datos);
    }

    const onGridReady = async (params) => {
        await filtrar(filter, params.api, null);
    };

    const onExport = async () => {
        setExportGuid(uuidv4())
    }

    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} iconName={'BiDownload'} onClick={onExport} />
                {!soloLectura &&
                    <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'}  onClick={nuevo}/>
                }
            </div>
        );
    };
    const editTanque = (id) => {
        setSidebarVisible(true);
        setCargaId(id);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setCargaId(0)
    }

    const RowButtons = ({data, rowIndex, api, context}) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editTanque(data.id)}/>
        </div>
    );
    return (
        <>
            <FormFilter columns={6}
                gridStyle={{ paddingTop: '0', marginTop: '0' }}
                filter={filter}
                onChange={onFilterChange}
                onClear={onClear}
                onFilter={(filtro) => filtrar(filtro, null)}>
                <Filters>
                    <Field label={"OC"} field="ordenCompra" render={FormTextBox} />
                    <Field label={"Fecha"} field="fecha" render={FormCalendarRange} />
                </Filters>
                <Buttons filter={true}
                    clear={true}
                    style={{ top: '-50px' }}
                >
                </Buttons>
            </FormFilter>
            <div className="tanques-container">
                {tanques.map((tanque) => (
                    <TanqueVista capacidad={tanque.capacidad}
                        disponible={tanque.disponible}
                        cantidadSegmentos={10}
                        porcentajeAdvertencia={tanque.porcAdvertencia}
                        porcentajeBandera={tanque.porcBandera}
                        ubicacion={tanque.ubicacion.nombre}
                        mostrarUbicacion={tanques.length > 1}
                    >
                    </TanqueVista>
                ))}

            </div>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    rowSelection={'single'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {   rowButtons: RowButtons,
                            buttonsHeader: ButtonsHeader
                        }
                    }
                    onGridReady={onGridReady}
                    context={{
                        editTanque: editTanque
                    }}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                >
                    <AgGridColumn field="ordenCompra" headerName="OC" cellClass={'center'} minWidth={80} />
                     <AgGridColumn field="fechaRegistro" valueFormatter={AgDateFormatter} headerName="FECHA" minWidth={100}  />
                    <AgGridColumn field="proveedor" headerName="PROVEEDOR" cellClass={'left'} minWidth={200} />
                    <AgGridColumn field="litros" headerName="LITROS" cellClass={'center'} minWidth={80} valueFormatter={agGridHelper.decimalFormatter} />
                    <AgGridColumn field="precio" headerName="PRECIO ($/Lt)" cellClass={'right'} minWidth={80} valueFormatter={AgCurrencyFormatter} />
                    <AgGridColumn field="controlador" headerName="CONTROLADOR" minWidth={100} />

                    <AgGridColumn maxWidth={70}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
             <CargaDetalle
                onCancel={() => setCargaId(null)}
                onSaved={() => {
                    filtrar(filter, null);
                    setCargaId(null);
                }}
                cargaId={cargaId}
                combustibleId={combustibleId}
                tanques={tanques}
            />
          
           
        </>
    )
}

export default Tanques;
