/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer, useState } from 'react';
import useApiViaje from '../../api/administracion/useApiMovimientos';
import FieldCalendar from "../../shared/controls/fieldCalendar";
import FieldDropDown from '../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../shared/controls/fieldTextArea";
import formReducer from '../../shared/forms/formReducer';
import FormHandler from "../../shared/forms/formHandler";
import { agregarDescuento, agregarGasto } from "./DescuentoValidacionEsquema";
import FieldAutoComplete from '../../shared/controls/fieldAutoComplete';
import alerts from "../../shared/alerts";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import constantes from '../constantes';
const AgregarGastoDescuento = ({ onSave, visible, hide }) => {
    const api = useApiViaje();
    const defaultEntity = () => ({
        entity: {
            operador: null,
            descuento: null,
            exhibiciones: 1,
            monto: 0,
            comentario: null,
            horasTiempoExtra:0,
            diasDeFalta:0
        }
    });
    const [submitted, setSubmitted] = useState(false);
    const tipoMovimientos = [{ id: 1, nombre: 'Descuento' }, { id: 2, nombre: 'Gasto' }];

    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);

    const formHandler = new FormHandler({
        validationSchema: agregarDescuento,
        api,
        dispatch
    });

    const formHandlerGasto = new FormHandler({
        validationSchema: agregarGasto,
        api,
        dispatch
    });

    const actualizar = (value, propertyName) => {
        dispatch({ type: 'update', value: value, name: propertyName });
    };

    const guardar = async () => {
        setSubmitted(true);
        if (entity.tipoMovimiento.id === 1) {
            const seguir = await formHandler.validate(entity);
            if (seguir.result) {
               // if(entity.descuento.id===3){entity.exhibiciones=1}
                await api.agregarDescuento(entity);
                onSave();
                dispatch({
                    type: "update",
                    value: { ...defaultEntity }
                });
            }
        }

        if (entity.tipoMovimiento.id === 2) {
            const seguir = await formHandlerGasto.validate(entity);
            if (seguir.result) {
                if (await alerts.preguntarSiNoAdvertencia('¿Esta seguro de guardar el gasto?')) {
                    await api.agregarGasto(entity);
                    onSave();
                    dispatch({
                        type: "update",
                        value: { ...defaultEntity }
                    });
                }
            }
        }
    };

    const close = () => {
        dispatch({
            type: "update",
            value: { ...defaultEntity }
        });
        hide();
    }

    function operadorNoEncontrado() {
        console.log(entity)
        return !((entity.operador?.id ?? 0) > 0);
    }

    const renderFooterModal = () => {
        return (
            <div>
                <Button label="Cancelar" onClick={close} className="p-button-outlined" />
                {(entity.tipoMovimiento?.id === 1 || entity.tipoMovimiento?.id === 2) &&
                    <Button label="Guardar" onClick={guardar} autoFocus />
                }
            </div>
        );
    }
console.log('desc')
    return (
        <Dialog header="Agregar descuento" visible={visible} style={{ width: '65vw' }} footer={renderFooterModal()} onHide={close}>
            <div className='form-row'>
                <FieldDropDown name="tipoMovimiento" options={tipoMovimientos} value={entity.tipoMovimiento} label="Tipo de movimiento"
                    required colMd={12} error={errors?.tipoMovimiento}
                    onChange={actualizar} />
                
                    <FieldAutoComplete name="operador" source="empleados" error={operadorNoEncontrado() === true && submitted} freeText showNotFound={true} label="Empleado"
                        value={entity.operador} onChange={actualizar} colMd={12} required />
                
                    <FieldTextBox name="puesto" label="Puesto" colMd={6} disabled={true}  value={entity.operador?.puesto}
                     onChange={actualizar} />
                      <FieldTextBox name="departamentoId" label="Departamento" colMd={6} disabled={true}   value={entity.operador?.departamento}
                     onChange={actualizar} />
            </div>
            <div className="form-row">
                {entity.tipoMovimiento?.id === 1 &&
                    <FieldDropDown name="descuento" source="descuentos" value={entity?.descuento} label="Descuento"
                        required colMd={6} error={errors?.descuento} onChange={actualizar} />
                }
                {entity.tipoMovimiento?.id === 2 &&
                    <>
                        <FieldDropDown name="tipoGasto" source="cajachica/tipoGastos" value={entity?.tipoGasto} label="Tipo de Gasto"
                            required colMd={6} error={errors?.tipoGasto}
                            onChange={actualizar} />
                        <FieldDropDown name="conceptoCajaChica" source="cajachica/conceptos" value={entity?.conceptoCajaChica} label="Concepto"
                            required colMd={6} error={errors?.conceptoCajaChica} params={{ tipoGastoId: entity?.tipoGasto?.id }}
                            onChange={actualizar} />
                    </>
                }                
                {((entity?.conceptoCajaChica?.id === constantes.conceptoRh.TiempoExtra || entity?.conceptoCajaChica?.id ===constantes.conceptoRh.QuintoDia)  && entity.tipoMovimiento?.id === 2 ) &&
                    <FieldInputNumber name="horasTiempoExtra" value={entity.horasTiempoExtra} fractionDigits={1} label="Horas"
                        required colMd={6} error={errors?.horasTiempoExtra}
                        onChange={actualizar} />
                }
                {(entity.tipoMovimiento?.id === 1 &&  entity?.descuento?.id ===3) &&
                    <FieldInputNumber name="diasDeFalta" value={entity.diasDeFalta} fractionDigits={1} label="Dias"
                        required colMd={6} error={errors?.diasDeFalta}
                        onChange={actualizar} />
                }
                {((entity.tipoMovimiento?.id === 1 &&  entity?.descuento?.id !==3) || (entity.tipoMovimiento?.id === 2 && entity?.conceptoCajaChica?.id !==constantes.conceptoRh.TiempoExtra  && entity?.conceptoCajaChica?.id !==constantes.conceptoRh.QuintoDia)) &&
                    <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                        required colMd={6} error={errors?.monto}
                        onChange={actualizar} />
                }
                {entity.tipoMovimiento?.id === 1 && entity?.descuento?.id !==3 &&
                    <>
                        <FieldInputNumber name="exhibiciones" value={entity.exhibiciones} fractionDigits={2} label="No. Exhibiciones"
                            required colMd={6} error={errors?.exhibiciones}
                            onChange={actualizar} />
                        <FieldCalendar name="fechaInicio" label="Fecha de inicio de descuento" colSm={6} required
                            value={entity.fechaInicio} className="xs" showButtonBar={false} error={errors?.fechaInicio} onChange={actualizar} />
                    </>
                }
                {(entity.tipoMovimiento?.id === 1 || entity.tipoMovimiento?.id === 2) &&
                    <FieldTextArea name="comentario" label="Comentario" value={entity.comentario} colSm={12}
                        onChange={actualizar} rows={4} errors={errors?.comentario} />
                }
            </div>
        </Dialog>
    )
}

export default AgregarGastoDescuento;