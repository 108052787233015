/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import useApiCapacitacion from "../../api/administracion/useApiCapacitacion";
import useForm from "../../shared/forms/useForm";
import Filters from "../../shared/forms/FormFilter/Filters";
import Field from "../../shared/forms/Field";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormCalendarRange from "../../shared/forms/FormFields/FormCalendarRange";
import moment from "moment";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import {FormattedDate} from "react-intl";
import CapacitacionDetalle from "./capacitacionDetalle";
import {getSessionState} from "../../hooks/useSessionState";
import useSessionState from "../../hooks/useSessionState";
import Departamentos from "../incidencias/Departamentos";


const filtroInicial = {
    fecha: [moment().startOf("month")._d, moment().startOf("day")._d],
    EnBaja: false
};

const Capacitaciones = () => {
    const api = useApiCapacitacion();
    const [datos, setDatos] = useState([]);
    const [capacitacionId, setCapacitacionId] = useState(null);
    const {values: filter, onChange: onFilterChange, onClear} = useForm(filtroInicial);
    const puedeEditar = true;
    const [,setId] = useState(null);
    const filtroKey = "FiltroCapacitaciones";
    const [filtro, setFiltro] = useSessionState(filtroKey, {}, filtroInicial);

    const filtrar = async (filtro, departamentoFiltroId) => {
        const newFiltro = {...filtro, departamentoId: departamentoFiltroId ?? departamentoId};
        const datos = await api.filtrar(newFiltro)
        setDatos(datos);
        setFiltro(filtro);
    };
    const [departamentoId, setDepartamentoId] = useState(0)  
    const nuevoCargo = (id) => {
        setId(id);
    };

    function exportar() {
        var filtro = getSessionState(filtroKey);
        if (filtro !== null) api.excel(filtro);
    }

    const ButtonsHeader = () => (
        <div className={"ag-grid header-icons"}>
            <ButtonIcon title={"Descargar"} onClick={exportar} iconName={"BiDownload"}/>

            {puedeEditar && (
                <ButtonIcon
                    title="Agregar"
                    className={"grid-action"}
                    iconName={"MdAddBox"}
                    onClick={() => setCapacitacionId(0)}
                />
            )}
        </div>
    );

    const DateCellRenderer = ({value}) => (
        <div>
            <FormattedDate value={value} weekday="short"/> &nbsp;
            {moment(value).format("DD/MM/YYYY")}
        </div>
    );

    const ButtonsDetail = ({data, context}) => (
        <div className={"ag-grid row-icons"}>
            {data.agregarCargo &&
                <ButtonIcon title={"Agregar Cargo"} iconName={"MdAddBox"} onClick={() => nuevoCargo(data.id)}/>
            }
            <ButtonIcon title={"Editar"} iconName={"FaRegEdit"} onClick={() => context.onEdit(data.id)}/>
        </div>
    );

    const onChangeDepartamento = async (id)=>{
        setDepartamentoId(id);
        await filtrar(filtro, id);
    }

    return (
        <div className="main-screen caja">
            <Departamentos departamentoActivoId={departamentoId} onChange={onChangeDepartamento}/>
            <div className="divider"/>
            <FormFilter columns={8} filter={filter} onChange={onFilterChange} onClear={onClear} onFilter={filtrar}>
                <Filters>
                    <Field field={"fecha"} label={"Fecha"} render={FormCalendarRange} cols={1}/>
                    
                    <Field
                        field={"Capacitador"}
                        label={"Capacitador"}
                        render={FormDropDown}
                        cols={3}
                        source="empleados"
                        params={{                                                                                         
                            todasLasEmpresas: true
                        }}
                        showClear={true}
                    />
                    
                    <Field
                        field={"tipoId"}
                        label={"Tipo de Capacitación"}
                        source="tipoCapacitacion"
                        render={FormMultiSelect}
                        cols={2}
                        showClear={true}
                    />
                 
                </Filters>
                <Buttons filter={true} clear={true}></Buttons>
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    components={{
                        buttonsHeader: ButtonsHeader,
                        buttonsDetail: ButtonsDetail,
                        dateCellRenderer: DateCellRenderer,
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{onEdit: (id) => setCapacitacionId(id)}}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn
                        field="fechaCapacitacion"
                        headerName="Fecha"
                        cellClass="center"
                        cellRenderer="dateCellRenderer"
                        maxWidth={150}
                    />
                    <AgGridColumn field="empleado" headerName="Empleado" flex={5} minWidth={240}/>       
                    <AgGridColumn field="temaCapacitacion" headerName="Tema Capacitación" cellClass="center"/>
                    <AgGridColumn field="duracion" headerName="Duración" width={70}/>
                    <AgGridColumn field="capacitador" headerName="Capacitador" flex={5} minWidth={240}/>                   
                    <AgGridColumn field="tipo" headerName="Tipo" cellClass="center"/>
                    <AgGridColumn field="calificacion" headerName="Calificación" cellClass="center"/>
                    <AgGridColumn field="formatoCapacitacion" headerName="Formato Capacitacion" cellClass="center"/>
                   
                    <AgGridColumn
                        maxWidth={80}
                        headerName="VER"
                        pinned={"right"}
                        headerComponent="buttonsHeader"
                        cellClass={"left"}
                        cellRenderer="buttonsDetail"
                    />
                </AgGridReact>
            </div>
            <CapacitacionDetalle
                capacitacionId={capacitacionId}
                puedeEditar={puedeEditar}
                onSaved={() => {
                    filtrar(filter).then();
                    setCapacitacionId(null);
                }}
                onCancel={() => setCapacitacionId(null)}
                onRefresh={() => filtrar(filter).then()}
                />
           
        </div>
    );
};

export default Capacitaciones;
