/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom'
import './layout.css'
import startCase from "lodash/startCase"
import ButtonIcon from "../shared/controls/ButtonIcon";
import {useLoadingState} from "./show-loading-context";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import useApiSource from "../api/source";
import jwt_decode from "jwt-decode";
import {useEmpresa} from "./EmpresaProvider";
import UserNotifications from "../shared/Notifications/UserNotifications";
import useApi from "../api/useApi";
import versionWeb from "../versionWeb";
import {SignalRContext} from "../App";
import {useAuth} from "../Seguridad/AuthProvider";

const Header = ({onEmpresaChanged}) => {
    const apiSource = useApiSource();
    const api = useApi()
    const {user:usuarioLogeado, logout} = useAuth();
    const breadCrumbs = getBreadCrumb(useLocation().pathname); //startCase(useLocation().pathname);
    const {datosPath} = useLoadingState();
    const [empresas, setEmpresas] = useState([]);
    const {empresa, actualizarDefault} = useEmpresa();
    const [ultimaVersion, setUltimaVersion] = useState(null);
    const [versionActual, setVersionActual] = useState(null);
    const [visibleVersion, setVisibleVersion] = useState(false)


    useEffect(() => {
        obtenerMenu().then();
        setVersionActual(versionWeb);
        obtenerVersion().then();
    }, []);

    const empresaClickHandler = (empresas, empresaId) => {
        const nuevaEmpresa = empresas.find(e => e.id === empresaId);
        onEmpresaChanged(empresaId, nuevaEmpresa);
    }

    const obtenerMenu = async () => {
        const result = await apiSource.obtener('empresas');
        const token = jwt_decode(usuarioLogeado.token);
        setEmpresas(result.map(e => {
            return {
                label: e.nombre,
                id: e.id,
                command: () => empresaClickHandler(result, e.id)
            }
        }));

        const seleccionada = result.find(e => e.id === parseInt(token.empresaId)) ?? {nombre: 'seleccionar Empresa'};
        actualizarDefault(seleccionada)
    }

    const obtenerVersion = async () => {
        const ultimaVersion = await api.get('version', {}, {showLoading: false, showError:false})
        setUltimaVersion(ultimaVersion)
        setVisibleVersion(true)
    }

    const menu = useRef(null);

    function getBreadCrumb(path) {
        path = (decodeURI(path))
        if (path === '/home')
            return []
        return path.split('/')
            .filter(e => e.length > 0 && isNaN(e) === true && e !== 'true' && e !== 'false')
            .map(e => startCase(e).replace('Configuracion', 'Configuración').replace('Facturacion', 'Facturación'));
    }

    const actualizarPagina = ()=>{
        window.location.reload(true);
    }

    SignalRContext.useSignalREffect(
        "nuevaVersion", // Your Event Key
        (version)=>{
            setUltimaVersion(version)
        }
    );

    return (
        <header className="header">
            <div className="aplicacion">
                <h1>
                    {breadCrumbs.map((breadCrumb, i) =>
                        <React.Fragment key={i}>
                            {breadCrumb}
                            {(i < breadCrumbs.length - 1) && <span> / </span>}
                        </React.Fragment>
                    )}
                    {datosPath ? ` / ${datosPath}` : ''}
                </h1>
            </div>
            {visibleVersion === true && ultimaVersion !== versionActual &&
                <span>
                    <Button label="¡Actualizar Version!" className="p-button-raised p-button-sm p-button-danger" onClick={actualizarPagina}>

                    </Button>
                </span>
            }
            {!empresa.hidden &&
                <div>
                    <Menu model={empresas} popup ref={menu} id="popup_menu"/>
                    <Button label={empresa?.nombre} onClick={(event) => menu.current.toggle(event)}
                            disabled={empresa.disabled}
                            className="p-button-outlined p-button-sm" aria-controls="popup_menu" aria-haspopup/>
                </div>
            }
            <span>{usuarioLogeado.nombre}</span>
            <UserNotifications user={usuarioLogeado}/>
            <a href="/#">
                <ButtonIcon title="Cerrar Sesión" iconName={'FaSignOutAlt'} onClick={logout}/>
            </a>
        </header>
    );
};

export default Header;
