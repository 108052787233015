import React from "react";
import { useState } from "react";
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import { Sidebar } from "primereact/sidebar";
import useApiFlotillas from "../../api/comercial/useApiFlotillas";
import FiltroFlotillas from "./filtroFlotillas";
import DetalleUnidad from "./detalleUnidad";
import { getSessionState } from "../../hooks/useSessionState";
import constantes from "../../catalogos/constantes";
import Filtro from "../../shared/componentes/filtro";
import CargaMasiva from "./CargaMasiva";
import { AgBoolFormatter } from "../../shared/ag-grid-helper/GridFormatters";
import useSeguridad from "../../Seguridad/useSeguridad";
import {Button} from "primereact/button";
import useApiGps from "../../api/operaciones/useApiGps";

const GestionDeFlotillas = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.gestionFlotillas);
    const filtroKey = 'filtroFlotillas';
    const [datos, setDatos] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [unidadId, setUnidadId] = useState(false);
    const [cargaMasivaVisible, setCargaMasivaVisible] = useState(false);
    const api = useApiFlotillas();
    const apiGps= useApiGps()
    const defaultFilter = {
        estatus: [
            constantes.estatus.unidades.enProceso,
            constantes.estatus.unidades.alta
        ]
    };

    async function buscar(filtro, paramsApi) {
        filtro = filtro ?? getSessionState(filtroKey);
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);

    }

    const onGridReady = async (params) => {
        setGridApi(params.api);
    };

    async function nuevo() {
        setSidebarVisible(true);
        setUnidadId(0);
    }

    function onUpdateStatus() {
        setSidebarVisible(false);
        buscar();
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }
    function exportarIncidencia(){
        api.excelIncidencia(getSessionState(filtroKey));
    }
    const ButtonsHeader = () => {
        return (
            <div className={'ag-grid header-icons'}>
                <ButtonIcon title={'Descargar'} onClick={exportar} iconName={'BiDownload'} />
                <ButtonIcon title={'Descargar Incidencias'} onClick={exportarIncidencia} iconName={'BiDownload'} />
                {!soloLectura &&
                    <>
                        <ButtonIcon title={'Carga masiva permiso de transito'} iconName={'BiUpload'} onClick={cargaMasiva} />
                        <ButtonIcon title="Agregar" className={'grid-action'} iconName={'MdAddBox'} onClick={nuevo} />
                    </>
                }
            </div>
        );
    };

    const editEmpleado = (id) => {
        setSidebarVisible(true);
        setUnidadId(id);
    };

    const RowButtons = ({ data, rowIndex, api, context }) => (
        <div className={'ag-grid row-icons'}>
            <ButtonIcon title={'Editar'} iconName={'FaRegEdit'} onClick={() => context.editEmpleado(data.id)} />
        </div>
    );

    async function cargaMasiva() {
        setCargaMasivaVisible(true);
    }

    async function cargar() {
        setCargaMasivaVisible(false);
        await buscar();
    }

    const onSincronizarGps = async () => {
        await apiGps.actualizarUnidades();
    }

    return (
        <>
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-bg" onHide={() => setSidebarVisible(false)}>
                <DetalleUnidad unidadId={unidadId} onSave={buscar} onUpdateStatus={onUpdateStatus} onHide={() => setSidebarVisible(false)}
                soloLectura={soloLectura} />
            </Sidebar>
            <Sidebar visible={cargaMasivaVisible} position="right" dismissable={false}
                className="p-sidebar-bg" onHide={() => setCargaMasivaVisible(false)}>
                <CargaMasiva onSave={cargar} />
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} defaultValue={defaultFilter} gridApi={gridApi}
                classButtons="field-iconos btn-group">
                <FiltroFlotillas />
            </Filtro>

            <div className="ag-theme-balham grid-principal" style={{position:'relative'}}>
                <Button label="Actualizar Relación Gps" onClick={onSincronizarGps} className="p-button-outlined" style={{    position: 'absolute',
                    top: '-50px',
                    width: '170px',
                    right: '5px'}
                }/>
                <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    frameworkComponents={
                        {
                            rowButtons: RowButtons,
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            buttonsHeader: ButtonsHeader,
                            dateFormatter: agGridHelper.DateFormatter,
                            imageFormatter: agGridHelper.ImageFormatter
                        }
                    }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                    context={{
                        editEmpleado: editEmpleado
                    }}
                    getRowClass={(params) => params.data.nombreColor}
                >
                    <AgGridColumn field="codigoUnidad" headerName="ID" flex={2} />
                    <AgGridColumn field="numeroEconomico" headerName="NÚMERO ECONÓMICO" />
                    <AgGridColumn field="tipoUnidad" headerName="TIPO" />
                    <AgGridColumn field="marca" headerName="MARCA" />
                    <AgGridColumn field="anio" headerName="Año" />
                    <AgGridColumn field="numeroSerie" headerName="NO. SERIE" />
                    <AgGridColumn field="placas" headerName="PLACA" />
                    <AgGridColumn field="estatusDocumentos" headerName="ESTATUS DOCS." />
                    <AgGridColumn field="estatus" headerName="ESTATUS UNIDAD" flex={3} />
                    <AgGridColumn field="suspendida" headerName="SUSPENDIDA" flex={2} cellRenderer={AgBoolFormatter} />
                    <AgGridColumn field="operadorAsignado" headerName="OPERADOR ASIGNADO" flex={2} />
                    <AgGridColumn field="coordinadorAsignado" headerName="COORDINADOR ASIGNADO" flex={2} />
                    <AgGridColumn field="suspendida" headerName="SUSPENDIDA" flex={2} />
                    <AgGridColumn maxWidth={180}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
};

export default GestionDeFlotillas;
