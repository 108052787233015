import useServiceFactory from "../serviceFactory";
const apiUrl = `/tanques`;

const useApiDieselTanque = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_DIESEL_API_URL });

    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        filtrarCargas: async (params) => service.get(`${apiUrl}/cargas`, {params}),
        obtenerCarga: async (id) => service.get(`${apiUrl}/${id}`, {}),
        obtenerPrecio: async (params) => service.get(`${apiUrl}/precio`, {params}),
        ultimoSello: async (unidadId) => service.get(`/cargas/unidades/${unidadId}/ultimoSello`),
        guardarCarga: async (params) => service.post(`${apiUrl}/cargas`, params),
        cargasUpdate: async (params) => service.put(`${apiUrl}/cargasUpdate`, params),
        // cancelar: async (params) => service.patch(`${apiUrl}/cancelar`, params),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `Cargas.xlsx`
        })
    }
};

export default useApiDieselTanque;
