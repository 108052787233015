/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import numeral from "numeral";
import _ from "lodash";

const Movimientos = ({ value, onChange, onValidate, puedeEditar, onRefresh }) => {
    console.log(value)
    return (
        <Form columns={1} values={value} onChange={onChange} onValidate={onValidate}
            formDisabled={!puedeEditar}>
            <Field field={'movimientos'} cols={5}
                render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value} getEntityId={(row) => row._uniqueUid} formDisabled={true}
                        onChange={(data) => onParentChange({ movimientos: data, costo: _.sumBy(data, 'costo') })}>
                        <ExColumn field="nombreCompleto" title={'Empleado'} width={'35%'} />
                        <ExColumn field="conceptoNombre" title={'Concepto'} width={'15%'} />
                        <ExColumn field="numeroExhibiciones" title={'No. Exhibiciones'} width={'15%'} />
                        <ExColumn field="monto" title={'Monto'}
                            cellRenderer={({ row }) => <span>{numeral(row?.monto).format('$0,0.00')}</span>} width={'15%'} />
                        <ExColumn field="nota" title={'Nota'} width={'20%'} />
                    </ExGrid>}>
            </Field>
        </Form>
    );
};

export default Movimientos;
