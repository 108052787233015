/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import _ from "lodash";

const Historial = ({ value, onChange, onValidate, puedeEditar, onRefresh }) => {
    return (
        <Form columns={1} values={value} onChange={onChange} onValidate={onValidate}
            formDisabled={!puedeEditar}>
            <Field field={'historial'} cols={5}
                render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value} getEntityId={(row) => row._uniqueUid} formDisabled={true}
                        onChange={(data) => onParentChange({ cargos: data, costo: _.sumBy(data, 'costo') })}>
                        <ExColumn field="accion" title={'Accion'} width={'35%'} />
                        <ExColumn field="auditUser" title={'Usuario'} width={'15%'} />
                        <ExColumn field="fechaUtc" title={'Fecha'} width={'15%'} />
                        <ExColumn field="comentarios" title={'Comentarios'} width={'35%'} />
                    </ExGrid>}>
            </Field>
        </Form>
    );
};

export default Historial;
