/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import useApiIncidencia from "../../../api/administracion/useApiIncidencia";
import useForm from "../../../shared/forms/useForm";
import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { serialize } from "object-to-formdata";
import FormCheckBox from "../../../shared/forms/FormFields/FormCheckBox";

const initialValues = {};

const Cargos = ({ incidenciaId, visible, onClose, onSaved }) => {
    const api = useApiIncidencia();
    const [submitted, setSubmitted] = useState(false);
    const {
        values: cargo,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);

    useEffect(() => {
        setValues(initialValues);
    }, [visible]);

    const onSave = async () => {
        setSubmitted(true);

        if (!isValid)
            return;

        const formData = serialize(cargo ?? {}, { indices: true, dotsForObjectNotation: true });
        await api.agregarCargo(incidenciaId, formData);
        onSaved();
    };

    const renderFooterModal = () => {
        return (
            <div className="p-1">
                <Button label="Cancelar" onClick={onClose} className="p-button-outlined" />
                <Button label="Guardar" onClick={onSave} type="button" />
            </div>
        );
    }

    return (
        <Dialog header="Nueva Incidencia" visible={visible} style={{ width: '50%' }}
            onHide={onClose} footer={renderFooterModal}>
            <Form columns={2} values={cargo} onChange={onChange} onValidate={onValidate}
                submitted={submitted}>
                <Field label={'Departamento'} field="departamentoId" title={'Departamento'} source="departamentos"
                    render={FormDropDown} required={submitted} />
                <Field label={'Concepto'} field="concepto" title={'Concepto'} render={FormTextBox} required={submitted} />
                <Field label={'Costo'} field="costo" title={'Costo'} render={FormInputNumber} required={submitted} />
                <Field label={'Evidencia'} field="evidencia" title={'Evidencia'} render={FormClipboardImage} required={submitted} />
                <Field label={'Cobro'} field="cobro" title={'Evidencia'} render={FormCheckBox} required={submitted} />
            </Form>
        </Dialog>
    );
};

export default Cargos;
